export function formDataToJSON(data) {
  var object = {};
  data.forEach((value, key) => (object[key] = value));
  return object;
}

export function getLastWeeksDate() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
}

export function getCurrenttWeeksDate() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}