import Card from "../components/Card";
import Input from "../components/Input";
import ResponsiveDivideLayout from "../components/ResponsiveDivideLayout";
import { useUser } from "../UserContext";

export default function Cuenta() {
  const user = useUser();
  console.log(user);
  return (
    <div className="w-full ">
      <div className="pb-16 mx-4 mt-4 md:mx-8">
        <h1 className="mb-8 text-2xl font-semibold">Cuenta</h1>
        <ResponsiveDivideLayout>
          <div className="p-4 text-left md:text-right basis-1/3 text-neutral-500">
            <h3 className="text-lg font-bold">General</h3>
            <p className="text-sm">Datos generales de usuario.</p>
          </div>
          <Card className="flex flex-col p-4 basis-2/3">
            <Input value={user.first_name} disabled className="flex-1" type="text" label="Nombre(s)" />
            <Input value={user.last_name} disabled className="flex-1" type="text" label="Apellido(s)" />
            <div className="flex space-x-4">
              <Input value={user.email} disabled className="flex-1" type="text" label="Email" />
              <Input value={user.phone} disabled className="flex-1" type="text" label="Teléfono" />
            </div>
          </Card>
        </ResponsiveDivideLayout>
      </div>
    </div>
  );
}
