import React from "react";

function ErrorMessage({ error_title = "Ocurrió un error", error_message = "", onClose }) {
    return (
      <div className="px-4 py-3 text-red-900 bg-red-100 border-t-4 border-red-500 rounded-b " role="alert">
        <div className="flex items-center mx-auto space-x-4 ">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <div className="flex-grow">
            <p className="m-0 font-bold">{error_title}</p>
            <p className="m-0 text-sm">{error_message}</p>
          </div>
          <button onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    );
  }
  

export default ErrorMessage;
