import React from 'react'
import { BsEye, BsGripVertical, BsPlayFill, BsZoomIn } from 'react-icons/bs'

export const DetailButton = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
      <span ref={resolvedRef} {...rest}> <BsEye /> </span>
      </>
    )
  }
)