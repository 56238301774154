import { useMachine } from "@xstate/react";
import axios from "axios";
import { useMemo, useState } from "react";
import { assign, createMachine } from "xstate";
import Card from "../../components/Card";
import ErrorMessage from "../../components/ErrorMessage";
import Spinner from "../../components/Spinner";
import Badge from "../../components/Badge";
import { useUser } from "../../UserContext";
import Table from "./Table";
import FilterModal from "./FilterModal";
import { getCurrenttWeeksDate, getLastWeeksDate } from "../../utils";
import DetailsModal from "./DetailsModal";
import moment from "moment";
import { COLUMNS } from "../../utils/DataColumns";

const transaccionesMachine = createMachine(
  {
    id: "transacciones-machine",
    initial: "getting",
    states: {
      //Mostrar pantalla con spinner cargando
      getting: {
        invoke: {
          src: "invoke_get_transacciones",
          onDone: [
            { target: "error_getting", actions: ["set_error_data"], cond: "is_error" },
            { target: "idle", actions: ["set_transacciones_data"] },
          ],
          onError: "error_getting",
        },
      },
      //Mostrar pantalla de error de toda la pagina
      error_getting: {
        on: {
          RETRY: "getting",
        },
      },
      //Mostrar la pantalla normal
      idle: {
        id: "idle",
        initial: "normal",
        states: {
          normal: {
            on: {
              SELECTED: { target: "details", actions: "set_selected" },
            },
          },
          details: {
            on: {
              CLOSE: "normal",
            },
          },
        },
        on: {
          FILTER: "filtering",
          RESET: 'reset_data'
        },
      },
      filtering: {
        initial: "normal",
        states: {
          normal: {
            on: {
              TYPING: { actions: "typing" },
              CANCEL: { target: "#idle", actions: "cancel_query_data" },
              CONFIRM: { target: "getting", actions: "set_query_data" },
            },
          },
          getting: {
            invoke: {
              src: "invoke_get_transacciones",
              onDone: { target: "#idle", actions: ["set_transacciones_data"] },
              onError: "error_getting",
            },
          },
          error_getting: {
            on: {
              DISMISS: "normal",
              CANCEL: { target: "#idle", actions: "cancel_query_data" },
              CONFIRM: { target: "getting", actions: "set_query_data" },
            },
          }
        },
      },
      reset_data: {
        initial: 'get_reset',
        states: {
          reseting: {
            on: {
              RESET: { target: "get_reset", actions: "reset_data"},
            },
          },
          get_reset: {
            invoke: {
              src: "invoke_reset_transacciones",
              onDone: { target: "#idle", actions: ["set_transacciones_data", 'reset_data'] },
              onError: "error_reset",
            },
          },
          error_reset: {
            on: {
              DISMISS: "reseting",
              CANCEL: { target: "#idle", actions: "cancel_query_data" },
              RESET: { target: "get_reset", actions: "set_query_data" },
            },
          }
        }
      }
    }
  },
  {
    services: {
      invoke_get_transacciones: async (context) => {
        const { fecha_inicial, fecha_final, order_id } = context.original;
        //TODO: Usar tienda correcta
        return axios({
          method: "get",
          url: `${process.env.REACT_APP_TRANSACTIONS_URL}?fecha_final=${fecha_final}&fecha_inicial=${fecha_inicial}&schema=prissa&ecommerce_order_id=${order_id}`,
          headers: { "Authorization-Dashboard": localStorage.getItem("mdp-dashboard-token") },
        });
      },
      invoke_reset_transacciones: async (context) => {
        //TODO: Usar tienda correcta
        return axios({
          method: "get",
          url: `${process.env.REACT_APP_TRANSACTIONS_URL}?fecha_final=${getCurrenttWeeksDate().toISOString().slice(0, 10)}&fecha_inicial=${getLastWeeksDate().toISOString().slice(0, 10)}&schema=prissa&ecommerce_order_id=`,
          headers: { "Authorization-Dashboard": localStorage.getItem("mdp-dashboard-token") },
        });
      }
    },
    actions: {
      typing: assign({ current: (context, event) => ({ ...context.current, [event.name]: event.value }) }),
      set_query_data: assign({ original: (context, event) => context.current }),
      cancel_query_data: assign({ current: (context, event) => context.original }),
      set_transacciones_data: assign({ transacciones_data: (context, event) => event.data.data }),
      set_error_data: assign({ error_data: (context, event) => event.data.data }),
      set_selected: assign({ selected: (context, event) => event.data }),
      reset_data: (context, event) => {
        const fecha_inicial = getLastWeeksDate().toISOString().slice(0, 10)
        const fecha_final = getCurrenttWeeksDate().toISOString().slice(0, 10)
        const order_id = ''
        context.current.fecha_inicial = fecha_inicial
        context.current.fecha_final = fecha_final
        context.current.order_id = order_id
      }
    },
    guards: {
      is_error: (context, event) => event.data.data.errorType,
    },
  }
);

export default function Transacciones() {
  const columns = useMemo (() => COLUMNS, [])
  const user = useUser();
  const [state, send] = useMachine(transaccionesMachine, {
    context: {
      user,
      transacciones_data: [],
      original: {
        fecha_inicial: getLastWeeksDate().toISOString().slice(0, 10),
        fecha_final: new Date().toISOString().slice(0, 10),
        order_id: '',
        status: "",
        store_id: "",
      },
      current: {
        fecha_inicial: getLastWeeksDate().toISOString().slice(0, 10),
        fecha_final: new Date().toISOString().slice(0, 10),
        order_id: '',
        status: "",
        store_id: "",
      },
    },
  });
  const data = useMemo(() => { 
    let olv = state.context.transacciones_data || [];
    //Transforma fechas de string a dates para que sorting funcione
    return olv.map((element) => {
      return {
        ...element,
        "fecha de creación de orden": moment(element["fecha de creación de orden"]).toDate(),
        "fecha de intento de compra": moment(element["fecha de intento de compra"]).toDate(),
        "fecha de pago": moment(element["fecha de pago"]).toDate(),
      };
    });
  }, [state.context.transacciones_data]);
 
  return (
    <div className="w-full">
      <div className="pb-16 mx-4 mt-4 md:mx-8">
        <div className="flex items-center justify-between mb-8">
          <div className="flex flex-col space-y-1">
            <h1 className="text-2xl font-semibold">Transacciones</h1>
            {!state.matches("getting") && !state.matches("error_getting") && (
              <h3 className="text-xs text-neutral-300">
                De {getLastWeeksDate().toISOString().slice(0, 10)} a {new Date().toISOString().slice(0, 10)}
              </h3>
            )}
          </div>
          {state.matches("getting") && <Spinner size="sm" />}
        </div>
        <FilterModal state={state} send={send} />
        <DetailsModal state={state} send={send} />
        {state.matches("error_getting") && <ErrorLoading onRetry={() => send("RETRY")} />}
        {!state.matches("getting") && !state.matches("error_getting") && (
          <div className="mb-4">
            <Table state={state} send={send} data={data} columns={columns} onFilter={() => send("FILTER")} onSelect={(row) => send("SELECTED", { data: row })} />
          </div>
        )}
      </div>
    </div>
  );
}

function ErrorLoading({ onRetry, ...props }) {
  return (
    <div className="flex justify-center">
      <Card className="flex flex-col items-center max-w-sm p-8 mt-16 space-y-4 text-center md:mt-32">
        <svg className="w-12 h-12 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
        </svg>
        <h3 className="font-bold text-red-500">Error</h3>
        <p className="text-sm">
          Se presentó un error al cargar la página,
          <br /> intentelo de nuevo
        </p>
        <button onClick={onRetry} className="px-4 py-2 mt-4 text-sm rounded-md hover:bg-primary-600 disabled:bg-primary-400 bg-primary-500 text-neutral-50">
          Reintentar
        </button>
      </Card>
    </div>
  );
}
