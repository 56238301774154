//Organizar los campos de cada sección para facilitar el chequeo de cambios
const all_fields = {
  general: ["callback_url", "callback_url_user", "callback_url_pass"],
  login_options: ["facebook_active", "fb_secret", "fb_app_id", "google_active", "google_secret", "google_app_id"],
  payment_options: [
    "card_mp_active",
    "oxxo_mp_active",
    "mp_public_key",
    "mp_access_token",
    "card_stripe_active",
    "stripe_account",
    "str_public_key",
    "str_secret_key",
    "spei_op_active",
    "paynet_op_active",
    "paypal_active",
    "paypal_client_id",
    "paypal_app_secret",
  ],
};

//Helper function para filtrar cambios por sección
function isDataDifferent(original, current, section) {
  const section_fields = all_fields[section];
  for (var field of section_fields) {
    if (original[field] !== current[field]) return true;
  }
  return false;
}

//Helper function para devolver los valores de una sección a los originales
function resetSection(original, current, section) {
  const section_fields = all_fields[section];
  var values = JSON.parse(JSON.stringify(current));
  for (var field of section_fields) {
    values[field] = original[field];
  }
  return values;
}

function dataForSection(current, section) {
  const section_fields = all_fields[section];
  var values = {};
  for (var field of section_fields) {
    values[field] = current[field];
  }
  return values;
}

export { dataForSection, resetSection, isDataDifferent, all_fields };
