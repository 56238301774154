import React from "react";
import { Navigate } from "react-router-dom";
import { useMachine } from "@xstate/react";
import axios from "axios";
import { assign, createMachine } from "xstate";
import Spinner from "./components/Spinner";

const tokenMachine = createMachine(
  {
    initial: "checking_token",
    states: {
      checking_token: {
        invoke: {
          src: "invoke_check_token",
          onDone: {
            target: "success",
            actions: "save_user",
          },
          onError: {
            target: "error",
            actions: "clean_token_storage",
          },
        },
      },
      error: {},
      success: {},
    },
  },
  {
    services: {
      invoke_check_token: () =>
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/dashboard-api/user`,
          headers: { "Authorization-Dashboard": localStorage.getItem("mdp-dashboard-token") },
        }),
    },
    actions: {
      save_user: assign({ user: (context, event) => event.data.data }),
      clean_token_storage: () => localStorage.removeItem("mdp-dashboard-token"),
    },
  }
);

function UserContextProvider(props) {
  const [state, send] = useMachine(tokenMachine);
  const { user = {} } = state.context;

  if (state.matches("checking_token"))
    return (
      <div className="flex flex-col items-center justify-center w-full h-screen space-y-4">
        <Spinner size="md" />
      </div>
    );
  if (state.matches("error")) return <Navigate to={"/login"} />;
  return <UserContext.Provider value={user} {...props} />;
}

const UserContext = React.createContext();
const useUser = () => React.useContext(UserContext);
export { UserContextProvider, useUser };
