import styled from 'styled-components'

export const Styles = styled.div`
.table {

  .th {
    width: auto;
  }

  .tr, .body {
    width: fit-content !important;
  }

  &.sticky {
    overflow: scroll;
    .header {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }
    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }
    .body {
      overflow-y: scroll;
      height: 650px;
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }
}
`;
