import React from "react";
import ErrorMessage from "../../components/ErrorMessage";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import SaveChanges from "../../components/SaveChanges";

function FilterModal({ state, send }) {
  const { fecha_inicial, fecha_final, order_id } = state.context.current;
  return (
    <Modal open={state.matches("filtering")} onClose={() => console.log("CLOSE")}>
      {state.matches({ filtering: "error_getting" }) && <ErrorMessage onClose={() => send("DISMISS")} />}
      <div className="p-4">
        <h2 className="mb-8 text-xl font-semibold">Filtrar Transacciones</h2>
        <div className="flex flex-col">
          <h3 className="text-mb font-semibold">Busqueda por fecha</h3>
          <div className="flex space-x-4">
            <Input
              id="fecha_inicial"
              name="fecha_inicial"
              className="flex-1"
              label="Fecha inicial"
              type="date"
              max={fecha_final}
              value={fecha_inicial}
              onChange={(event) => send({ type: "TYPING", value: event.target.value, name: event.target.name })}
            />
            <Input
              id="fecha_final"
              name="fecha_final"
              className="flex-1"
              label="Fecha final"
              type="date"
              min={fecha_inicial}
              value={fecha_final}
              onChange={(event) => send({ type: "TYPING", value: event.target.value, name: event.target.name })}
            />
          </div>
          <h3 className="text-mb font-semibold">Busqueda por ID de orden</h3>
          <Input
              id="orden_id"
              name="order_id"
              className='flex-1'
              type='text'
              value={order_id}
              onChange={(event) => send({ type: "TYPING", value: event.target.value, name: event.target.name })}
              placeholder={`Orden ID`}
            />
          {/*<Selector label="Estatus">
              <option>Todos</option>
            </Selector>
            <Selector label="Procesador">
              <option>Todos</option>
            </Selector>*/}
        </div>
        <SaveChanges className="justify-end" saving={state.matches({ filtering: "getting" })} onSave={() => send("CONFIRM")} onCancel={() => send("CANCEL")} />
      </div>
    </Modal>
  );
}
export default FilterModal;
