import React from "react";
import Spinner from "./Spinner";

function SaveChanges({ children, className, saving, onSave, onCancel, ...props }) {
  return (
    <div className={`flex self-end space-x-4 ${className}`} {...props}>
      <button disabled={saving} className="px-4 py-2 mt-4 text-sm border rounded-md hover:text-primary-600 disabled:text-primary-400 text-primary-500 border-primary-500" onClick={onCancel}>
        Cancelar
      </button>
      {!onSave ? (
        <button type="submit" disabled={saving} className="px-4 py-2 mt-4 text-sm rounded-md hover:bg-primary-600 disabled:bg-primary-400 bg-primary-500 text-neutral-50">
          {!saving ? (
            "Guardar cambios"
          ) : (
            <div className="flex space-x-2">
              <Spinner size="sm" />
              <div>Guardando...</div>
            </div>
          )}
        </button>
      ) : (
        <button disabled={saving} className="px-4 py-2 mt-4 text-sm rounded-md hover:bg-primary-600 disabled:bg-primary-400 bg-primary-500 text-neutral-50" onClick={onSave}>
          {!saving ? (
            "Guardar cambios"
          ) : (
            <div className="flex space-x-2">
              <Spinner size="sm" />
              <div>Guardando...</div>
            </div>
          )}
        </button>
      )}
    </div>
  );
}

export default SaveChanges;
