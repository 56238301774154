import React from 'react'

export const ColumnFilter = ({column}) => {
  const {filterValue, setFilter, preFilteredRows, id} = column
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <div>
      <select
        className='w-fit text-xs'
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option className='py-5 border-b-1' value="">ver todo</option>
        {options.map((option, i) => (
          <option
            className='py-5 border-b-1'
            key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>


    
  )
}
