import React from "react";
import Spinner from "../../components/Spinner";

function ResetData({ children, className, saving, onSave,state, order_id, ...props }) {
  return (
    <div className={`flex self-end space-x-4 ${className}`} {...props}>
      {!onSave ? (
        <button type="submit" disabled={saving} className="p-2 text-sm rounded-md hover:bg-primary-600 disabled:bg-primary-400 bg-primary-500 text-neutral-50">
          {!saving ? (
            "Borrar filtros"
          ) : (
            <div className="flex space-x-2">
              <Spinner size="sm" />
              <div>Guardando...</div>
            </div>
          )}
        </button>
      ) : (
        <button disabled={saving} className="p-2 text-sm rounded-md hover:bg-primary-600 disabled:bg-primary-400 bg-primary-500 text-neutral-50" onClick={onSave}>
          {!saving ? (
            "Borrar filtros"
          ) : (
            <div className="flex space-x-2">
              <Spinner size="sm" />
              <div>Guardando...</div>
            </div>
          )}
          
        </button>
      )}
    </div>
  );
}

export default ResetData;
