import React from "react";

function ResponsiveDivideLayout({ children, className, ...props }) {
  return (
    <div className={`flex flex-col space-x-0 space-y-2 md:space-y-0 md:space-x-8 md:flex-row ${className}`} {...props}>
      {children}
    </div>
  );
}

export default ResponsiveDivideLayout;
