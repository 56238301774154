import Card from "../../components/Card";
import ResponsiveDivideLayout from "../../components/ResponsiveDivideLayout";
import Plot from "react-plotly.js";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { assign, createMachine } from "xstate";
import axios from "axios";
import { getLastWeeksDate } from "../../utils";
import { useMachine } from "@xstate/react";
import { useUser } from "../../UserContext";
import FilterModal from "./FilterModal";
import moment from "moment";
const analyticsMachine = createMachine(
  {
    id: "analytics-machine",
    initial: "getting",
    states: {
      //Mostrar pantalla con spinner cargando
      getting: {
        invoke: {
          src: "invoke_get_transacciones",
          onDone: [
            { target: "error_getting", actions: ["set_error_data"], cond: "is_error" },
            { target: "idle", actions: ["set_transacciones_data", "initialize_tab"] },
          ],
          onError: "error_getting",
        },
      },
      //Mostrar pantalla de error de toda la pagina
      error_getting: {
        on: {
          RETRY: "getting",
        },
      },
      //Mostrar la pantalla normal
      idle: {
        id: "idle",
        initial: "normal",
        states: {
          normal: {
            on: {
              SELECTED: { target: "details", actions: "set_selected" },
              TAB_SELECTED: { actions: "change_tab" },
            },
          },
          details: {
            on: {
              CLOSE: "normal",
            },
          },
        },
        on: {
          FILTER: "filtering",
        },
      },
      filtering: {
        initial: "normal",
        states: {
          normal: {
            on: {
              TYPING: { actions: "typing" },
              CANCEL: { target: "#idle", actions: "cancel_query_data" },
              CONFIRM: { target: "getting", actions: "set_query_data" },
            },
          },
          getting: {
            invoke: {
              src: "invoke_get_transacciones",
              onDone: { target: "#idle", actions: ["set_transacciones_data"] },
              onError: "error_getting",
            },
          },
          error_getting: {
            on: {
              DISMISS: "normal",
              CANCEL: { target: "#idle", actions: "cancel_query_data" },
              CONFIRM: { target: "getting", actions: "set_query_data" },
            },
          },
        },
      },
    },
  },
  {
    services: {
      invoke_get_transacciones: async (context) => {
        const { fecha_inicial, fecha_final } = context.original;
        //TODO: Usar tienda correcta
        return axios({
          method: "get",
          url: `${process.env.REACT_APP_ANALYTICS_URL}?fecha_final=${fecha_final}&fecha_inicial=${fecha_inicial}&schema=prissa`,
          headers: { "Authorization-Dashboard": localStorage.getItem("mdp-dashboard-token") },
        });
      },
    },
    actions: {
      initialize_tab: assign({ seccion: (context, event) => Object.keys(event.data.data)[0] }),
      change_tab: assign({ seccion: (context, event) => event.data }),
      typing: assign({ current: (context, event) => ({ ...context.current, [event.name]: event.value }) }),
      set_query_data: assign({ original: (context, event) => context.current }),
      cancel_query_data: assign({ current: (context, event) => context.original }),
      set_transacciones_data: assign({ transacciones_data: (context, event) => event.data.data }),
      set_error_data: assign({ error_data: (context, event) => event.data.data }),
      set_selected: assign({ selected: (context, event) => event.data }),
    },
    guards: {
      is_error: (context, event) => event.data.data.errorType,
    },
  }
);

export default function Analytics() {
  const user = useUser();
  const [state, send] = useMachine(analyticsMachine, {
    context: {
      user,
      original: {
        fecha_inicial: getLastWeeksDate().toISOString().slice(0, 10),
        fecha_final: new Date().toISOString().slice(0, 10),
        status: "",
        store_id: "",
      },
      current: {
        fecha_inicial: getLastWeeksDate().toISOString().slice(0, 10),
        fecha_final: new Date().toISOString().slice(0, 10),
        status: "",
        store_id: "",
      },
    },
  });
  const { original, transacciones_data, seccion } = state.context;

  return (
    <div className="w-full ">
      <div className="pb-16 mx-4 mt-4 md:mx-8">
        <div className="flex items-center justify-between mb-8">
          <div className="flex flex-col space-y-1">
            <h1 className="text-2xl font-semibold">Analytics</h1>
            {!state.matches("getting") && !state.matches("error_getting") && (
              <h3 className="text-xs text-neutral-300">
                De {moment(original.fecha_inicial).format('DD MMM')} a {moment(original.fecha_final).format('DD MMM')}
              </h3>
            )}
          </div>
          {state.matches("getting") ? (
            <Spinner size="sm" />
          ) : (
            <button onClick={() => send("FILTER")} className="flex items-center p-2 space-x-2 border rounded-md hover:border-primary-500 hover:bg-neutral-100">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary-500">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                />
              </svg>
              <div className="hidden text-neutral-500 md:block">Filtrar</div>
            </button>
          )}
        </div>
        <FilterModal state={state} send={send} />
        {!state.matches("getting") && !state.matches("error_getting") && (
          <>
            <div className="flex mb-8 overflow-x-auto">
              {Object.entries(transacciones_data).map(function ([key, value], index) {
                if (key === seccion) return <button className="flex-grow-0 flex-shrink-0 px-4 py-2 font-bold border-b-2 text-neutral-500 border-primary-700">{value.name}</button>;
                else
                  return (
                    <button
                      key={index}
                      className="flex-grow-0 flex-shrink-0 px-4 py-2 text-neutral-500"
                      onClick={() => {
                        send("TAB_SELECTED", { data: key });
                      }}>
                      {value.name}
                    </button>
                  );
              })}
            </div>
            <div className="flex flex-col space-y-16">
              {transacciones_data[seccion].charts.map((grafica, index) => {
                return (
                  <ResponsiveDivideLayout key={index}>
                    <div className="p-4 text-left md:text-right basis-1/3 text-neutral-500">
                      <h3 className="text-lg font-bold">{grafica.title}</h3>
                      <p className="text-sm">{grafica.description}</p>
                    </div>
                    <Card className="p-4 basis-2/3 aspect-square md:aspect-[3/2] lg:aspect-[2/1]">
                      <Plot
                        data={grafica.chart.data}
                        layout={grafica.chart.layout}
                        config={grafica.chart.config || {}}
                        useResizeHandler={true}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Card>
                  </ResponsiveDivideLayout>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
