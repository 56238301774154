import { useMachine } from "@xstate/react";
import axios from "axios";
import { createMachine, assign } from "xstate";
import Card from "../../components/Card";
import ErrorMessage from "../../components/ErrorMessage";
import Spinner from "../../components/Spinner";
import GeneralSection from "./GeneralSection";
import LoginOptionsSection from "./LoginOptionsSection";
import PaymentOptionsSection from "./PaymentOptionsSection";
import { dataForSection, resetSection } from "./utils";

const configMachine = createMachine(
  {
    id: "config-machine",
    initial: "getting",
    states: {
      //Mostrar pantalla con spinner cargando
      getting: {
        invoke: {
          src: "invoke_get_store",
          onDone: { target: "idle", actions: ["initialize_all"] },
          onError: "error_getting",
        },
      },
      //Mostrar pantalla de error de toda la pagina
      error_getting: {
        on: {
          RETRY: "getting",
        },
      },
      //Mostrar la pantalla normal
      //Mostrar botón de guardar en cada sección si sus datos han cambiado
      idle: {
        on: {
          TYPING: { actions: "typing" },
          TOGGLE: { actions: "toggle" },
          SAVE_CHANGES: { target: "saving_changes" },
          CANCEL_CHANGES: { actions: "reset_section" },
        },
      },
      //Mostrar spinner en botón, cambiar leyenda a Guardando cambios
      saving_changes: {
        invoke: {
          src: "invoke_save_store",
          onDone: { target: "idle", actions: "refresh_original" },
          onError: { target: "error_saving" },
        },
      },
      //Mostrar Modal de error
      error_saving: {
        on: {
          TYPING: { actions: "typing" },
          TOGGLE: { actions: "toggle" },
          SAVE_CHANGES: { target: "saving_changes" },
          CANCEL_CHANGES: { actions: "reset_section" },
          DISMISS: "idle",
        },
      },
    },
  },
  {
    services: {
      invoke_get_store: async (context) => {
        return  axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/dashboard-api/store`,
          headers: { "Authorization-Dashboard": localStorage.getItem("mdp-dashboard-token") },
        });
      },
      invoke_save_store: async (context, event) => {
        const { current } = context;
        return axios({
          method: "put",
          url: `${process.env.REACT_APP_API_URL}/dashboard-api/store`,
          data: dataForSection(current, event.section),
          headers: { "Authorization-Dashboard": localStorage.getItem("mdp-dashboard-token") },
        });
      },
    },
    actions: {
      initialize_all: assign({ current: (context, event) => event.data.data, original: (context, event) => event.data.data }),
      typing: assign({ current: (context, event) => ({ ...context.current, [event.name]: event.value }) }),
      toggle: assign({ current: (context, event) => ({ ...context.current, [event.name]: !context.current[event.name] }) }),
      refresh_original: assign({ original: (context, event) => event.data.data }),
      reset_section: assign({ current: (context, event) => resetSection(context.original, context.current, event.section) }),
    },
  }
);

export default function Configuracion() {
  const [state, send] = useMachine(configMachine);

  return (
    <div className="w-full">
      {state.matches("error_saving") && (
        <div className="sticky z-30 md:top-16 top-32">
          <ErrorMessage error_message="Ocurrio un error al guardar los cambios" onClose={() => send("DISMISS")} />
        </div>
      )}
      <div className="pb-16 mx-4 mt-4 md:mx-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl font-semibold">Configuración</h1>
          {state.matches("getting") && <Spinner size="sm" />}
        </div>
        {state.matches("error_getting") && <ErrorLoading onRetry={() => send("RETRY")} />}
        {!state.matches("getting") && !state.matches("error_getting") && (
          <div className="flex flex-col space-y-16 ">
            <GeneralSection state={state} send={send} />
            <LoginOptionsSection state={state} send={send} />
            <PaymentOptionsSection state={state} send={send} />
          </div>
        )}
      </div>
    </div>
  );
}

function ErrorLoading({ onRetry, ...props }) {
  return (
    <div className="flex justify-center">
      <Card className="flex flex-col items-center max-w-sm p-8 mt-16 space-y-4 text-center md:mt-32">
        <svg className="w-12 h-12 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
        </svg>
        <h3 className="font-bold text-red-500">Error</h3>
        <p className="text-sm">
          Se presentó un error al cargar la página,
          <br /> intentelo de nuevo
        </p>
        <button onClick={onRetry} className="px-4 py-2 mt-4 text-sm rounded-md hover:bg-primary-600 disabled:bg-primary-400 bg-primary-500 text-neutral-50">
          Reintentar
        </button>
      </Card>
    </div>
  );
}
