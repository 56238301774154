import React from "react";

function Badge(props) {
  if (props.type === "success") {
    return <div className={` border-neutral-300 border inline-block px-2 py-1  rounded-md shadow-sm text-white bg-green-700 ${props.className}`}>{props.children}</div>;
  } else if (props.type === "warning") {
    return <div className={` border-neutral-300 border inline-block px-2 py-1  rounded-md shadow-sm text-white bg-yellow-600 ${props.className}`}>{props.children}</div>;
  } else if (props.type === "danger") {
    return <div className={` border-neutral-300 border inline-block px-2 py-1  rounded-md shadow-sm text-white bg-red-700 ${props.className}`}>{props.children}</div>;
  } else {
    return <div className={` border-neutral-300 border inline-block px-2 py-1  rounded-md shadow-sm text-neutral-700 bg-white ${props.className}`}>{props.children}</div>;
  }
}

export default Badge;
