import moment from "moment";
import Badge from "../components/Badge";
export const COLUMNS = [
    {
      Header: <div>Fecha de<br/>creación de orden</div>,
      accessor: "fecha de creación de orden",
      sortType: "datetime",
      disableFilters: true,
      Cell: (props) => {
        return (
          <div className="text-xs">
            <div>{moment(props.value).format("DD MMM YYYY")}</div>
            <div>{moment(props.value).format("HH:mm")}</div>
          </div>
        );
      },
    },
    {
      Header: <div>Fecha de<br/>intento de compra</div>,
      accessor: "fecha de intento de compra",
      sortType: "datetime",
      disableFilters: true,
      Cell: (props) => {
        return (
          <div className="text-xs">
            <div>{moment(props.value).format("DD MMM YYYY")}</div>
            <div>{moment(props.value).format("HH:mm")}</div>
          </div>
        );
      },
    },
    {
      Header: "Fecha de Pago",
      accessor: "fecha de pago",
      sortType: "datetime",
      disableFilters: true,
      Cell: (props) => {
        return (
          <div className="text-xs">
            <div>{moment(props.value).format("DD MMM YYYY")}</div>
            <div>{moment(props.value).format("HH:mm")}</div>
          </div>
        );
      },
    },
    {
      Header: "Estatus",
      accessor: "status de orden",
      disableSortBy: true,
      Cell: (props) => {
        if (props.value === "Pagado")
          return (
            <Badge type="success" className="text-xs font-bold border-none">
              {props.value}
            </Badge>
          );
        else if (props.value === "Error")
          return (
            <Badge type="danger" className="text-xs font-bold border-none">
              {props.value}
            </Badge>
          );
        else {
          return <Badge className="text-xs font-bold">{props.value}</Badge>;
        }
      },
    },
    {
      Header: "Riesgo",
      accessor: "riesgo de fraude",
      disableSortBy: true,
      Cell: (props) => {
        if (props.value === "nulo")
          return (
            <Badge type="success" className="text-xs font-bold border-none">
              {props.value}
            </Badge>
          );
        else if (props.value === "bajo")
          return (
            <Badge type="warning" className="text-xs font-bold border-none">
              {props.value}
            </Badge>
          );
        else if (props.value === "alto")
          return (
            <Badge type="danger" className="text-xs font-bold border-none">
              {props.value}
            </Badge>
          );
        else {
          return <Badge className="text-xs font-bold">{props.value}</Badge>;
        }
      },
    },
    {
      Header: "Procesador",
      accessor: "procesador",
      disableSortBy: true
    },
    {
      Header: "Tipo de pago",
      accessor: "tipo de pago",
      className: 'custom-w',
      disableSortBy: true
    },
    {
      Header: "Importe",
      accessor: "monto de compra",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        return <div className="text-center">{"$ " + props.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>;
      },
    },
    {
      Header: "Estatus de procesador",
      accessor: "status de orden en procesador",
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "ID de orden",
      accessor: "id de orden",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        return props.value.slice(-12);
      },
    },
    {
      Header: "ID en procesador",
      accessor: "id de compra en procesador",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        return props.value;
      },
    },
    {
      Header: "Nombre de cliente",
      accessor: "nombre de cliente",
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "Email",
      accessor: "email de cliente",
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "ID de cliente",
      accessor: "id de cliente",
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "Método de entrega",
      accessor: "método de entrega",
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "Ciudad",
      accessor: "ciudad",
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "Código Postal",
      accessor: "código postal",
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "Teléfono",
      accessor: "teléfono",
      disableFilters: true,
      disableSortBy: true
    },
  ]

export const HEADERS = [
  {label: 'Fecha de creación de orden', key: 'fecha de creación de orden'},
  {label: 'Fecha de intento de compra', key: 'fecha de intento de compra'},
  {label: 'Fecha de pago', key: 'fecha de pago'},
  {label: 'Status', key: 'status de orden'},
  {label: 'Riesgo', key: 'riesgo de fraude'},
  {label: 'Procesador', key: 'procesador'},
  {label: 'Tipo de pago', key: 'tipo de pago'},
  {label: 'Importe', key: 'monto de compra'},
  {label: 'Estatus procesador', key: 'status de orden en procesador'},
  {label: 'ID de orden', key: 'id de orden'},
  {label: 'ID de compra en procesador', key: 'id de compra en procesador'},
  {label: 'Nombre de cliente', key: 'nombre de cliente'},
  {label: 'Email', key: 'email de cliente'},
  {label: 'ID de cliente', key: 'id de cliente'},
  {label: 'Método de entrega', key: 'método de entrega'},
  {label: 'Ciudad', key: 'ciudad'},
  {label: 'Código postal', key: 'código postal'},
  {label: 'Teléfono', key: 'teléfono'},

]