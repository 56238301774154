import React from "react";

function Input({ label = "", className, ...props }) {
  return (
    <div className={`mb-4 ${className}`}>
      <label className="block mb-2 text-sm font-medium text-neutral-500">{label}</label>
      <input {...props} />
    </div>
  );
}

export default Input;
