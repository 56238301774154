import React, { useState, useMemo, useRef  } from "react";
import { useTable, useGlobalFilter, usePagination, useAsyncDebounce, useSortBy, useFilters, useRowSelect, useFlexLayout, useBlockLayout } from "react-table";
import { useSticky } from 'react-table-sticky'
import Card from "../../components/Card";
import { ColumnFilter } from "../../components/filtersColumns/ColumnFilter";
import { Checkbox } from "../../components/Checkbox";
import { CSVLink } from "react-csv";
import { HEADERS } from "../../utils/DataColumns";
import { motion, AnimatePresence } from 'framer-motion'
import { BsArrowDown, BsArrowUp, BsBoxArrowUp, BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { DetailButton } from "../../components/DetailButton";
import { Styles } from "../../components/TableStyles";
import ResetData from "./ResetData";
import { getLastWeeksDate } from "../../utils";
import moment from "moment";


function Table({ columns, data, onFilter, onSelect, state, send }) {

  const defaultColumn = useMemo(() => {
    return {
        Filter: ColumnFilter
    }
}, [])

  const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 20 }, defaultColumn }, useGlobalFilter, useFilters, useSortBy, useSticky, useBlockLayout, usePagination, useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps}) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({row}) => <Checkbox {...row.getToggleRowSelectedProps()} />
          },
          {
            id: 'deatil',
            Cell: ({row}) => <DetailButton onClick={() => onSelect(row.original)} />
          },
          ...columns
        ]
      })
    }
    );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    preGlobalFilteredRows,
    setGlobalFilter
  } = tableInstance;

  const dataExport = selectedFlatRows.map((row) => row.original)
  const headerExport = HEADERS
  const csvDownloadRef = useRef(null)

    const spring = React.useMemo(
    () => ({
      type: 'spring',
      damping: 50,
      stiffness: 100,
    }),
    []
  )

  let haveDate = false
  let haveID = false

  state.context.current.fecha_inicial === getLastWeeksDate().toISOString().slice(0, 10) && state.context.current.fecha_final === new Date().toISOString().slice(0, 10) ? haveDate = false : haveDate = true
  state.context.current.order_id === '' ? haveID = false : haveID = true
  
  return (
    <div>
      <div className="flex justify-end mb-2 md:-mt-16">
        <div className="flex space-x-2 md:max-w-sm">
         {/* <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />*/}
          <button onClick={onFilter} className="flex items-center p-2 space-x-2 border rounded-md hover:border-primary-500 hover:bg-neutral-100">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary-500">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
              />
            </svg>
            <div className="hidden text-neutral-500 md:block">Filtrar</div>
          </button>
          {dataExport.length > 0 ? 
            <CSVLink data={dataExport} headers={headerExport} filename='Transacciones.csv' target='_blank' ref={csvDownloadRef}>
              <button className="flex items-center p-2 space-x-2 border rounded-md hover:border-primary-500 hover:bg-neutral-100">
                <BsBoxArrowUp /> <span> Exportar</span>
              </button>
            </CSVLink>
            :
            <button className="flex items-center p-2 space-x-2 border rounded-md hover:border-primary-500 hover:bg-neutral-100">
              <BsBoxArrowUp /> <span> Exportar</span>
            </button>
          }
        </div>

      </div>
      {haveDate || haveID ? 
          <div className="flex justify-between mb-1">
            <div className="">
              <h3 className="flex items-center justify-between flex-nowrap text-sm font-semibold">filtros activos:</h3>
              {state.context.current.fecha_inicial === getLastWeeksDate().toISOString().slice(0, 10) && state.context.current.fecha_final === new Date().toISOString().slice(0, 10) ? '' : <span className="text-xs border-primary-500 border-2 rounded-2xl px-5 mr-2">{moment(state.context.current.fecha_inicial).format("DD MMM YYYY")} - {moment(state.context.current.fecha_final).format("DD MMM YYYY")}</span>}
              {state.context.current.order_id === '' ? '' : <span className="text-xs border-primary-500 border-2 rounded-2xl px-5">{state.context.current.order_id}</span>}
            </div>
            <ResetData className="mb-4 mt-2 justify-end" saving={state.matches({ reset_data: "get_reset" })} onSave={() => send("RESET")} />
          </div>
        : ''
        }
      <Card className="overflow-x-auto">
        <Styles >
          <div {...getTableProps()} className="w-full text-sm border border-collapse table-auto border-neutral-200 table sticky" >
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div className="tr"  {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                     <motion.div {...column.getHeaderProps(column.getSortByToggleProps(), {
                          layoutTransition: spring,
                          style: {
                            minWidth: column.minWidth,
                          },
                          },
                        )}
                      {...column.getHeaderProps()} className="flex align-middle w-36 px-2 py-2 overflow-x-hidden text-center truncate border-y border-neutral-200 bg-neutral-100 th">
                      <div className="whitespace-normal	font-bold flex items-center align-middle justify-center flex-nowrap space-x-2">
                        <span>{column.render("Header")}</span>
                        <span>
                          {column.isSorted &&
                            (column.isSortedDesc ? (
                              <BsArrowUp />
                              ) : (
                                <BsArrowDown />
                              ))}
                            </span>
                      </div>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                      </motion.div>
                  ))}
                </div>
              ))}
            </div>
            <div  className="body" {...getTableBodyProps()}>
              <AnimatePresence>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <motion.div 
                      {...row.getRowProps({
                        layoutTransition: spring,
                        exit: { opacity: 0, maxHeight: 0 },
                      })}
                      className="cursor-pointer hover:bg-neutral-100 tr" {...row.getRowProps()} >
                      {row.cells.map((cell) => {
                        return (
                          <motion.div {...cell.getCellProps()} className="w-32 px-2 py-2 overflow-x-hidden text-center truncate border-y border-neutral-200 td">
                            {cell.render("Cell")}
                          </motion.div>
                        );
                      })}
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </div >
          </div >
        </Styles>
      </Card>

      <div className="flex flex-col items-center mt-8 space-y-4 md:flex-row md:items-start md:space-y-0 md:justify-between">
        <select
          className="max-w-xs"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}>
          {[20, 30, 50, 60].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </select>
        <div className="flex flex-col items-center space-y-4">
          <div className="flex justify-center space-x-2">
            <button className="flex items-center justify-center w-8 h-8 rounded-sm bg-primary-600 text-neutral-50" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <BsChevronDoubleLeft />
            </button>
            <button className="flex items-center justify-center w-8 h-8 rounded-sm bg-primary-600 text-neutral-50" onClick={() => previousPage()} disabled={!canPreviousPage}>
              <BsChevronLeft />
            </button>
            <button className="flex items-center justify-center w-8 h-8 rounded-sm bg-primary-600 text-neutral-50" onClick={() => nextPage()} disabled={!canNextPage}>
              <BsChevronRight />
            </button>
            <button className="flex items-center justify-center w-8 h-8 rounded-sm bg-primary-600 text-neutral-50" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <BsChevronDoubleRight />
            </button>
          </div>
          <span>
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>
        </div>
      </div>
    </div>
  );
}

{/*function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  return (
    <input
      type="text"
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Buscar`}
    />
  );
}*/}

export default Table;
