import moment from "moment";
import React from "react";
import { useState } from "react";
import Badge from "../../components/Badge";
import Modal from "../../components/Modal";

function DetailsModal({ state, send }) {
  const [tab, setTab] = useState("general");
  const { selected = {} } = state.context;

  return (
    <Modal open={state.matches({ idle: "details" })} onClose={() => console.log("CLOSE")}>
      <div className="p-4">
        <div className="flex flex-col mb-4 space-y-1">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-semibold text-neutral-500">Detalles de Transaccion</h3>
            <button className="text-neutral-300" onClick={() => send("CLOSE")}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex items-center space-x-4">
            <Badge>
              <div className="text-xs">{selected["riesgo de fraude"]}</div>
            </Badge>
            <div className="text-xs text-neutral-300">{selected["id de orden"]}</div>
          </div>
        </div>
        <div className="flex mb-4 overflow-x-scroll text-sm shadow-">
          <button className={`flex-grow-0 flex-shrink-0 px-4 py-2  text-neutral-500 ${tab === "general" ? "font-bold border-b-2 border-primary-700" : ""} `} onClick={() => setTab("general")}>
            General
          </button>
          <button className={`flex-grow-0 flex-shrink-0 px-4 py-2 text-neutral-500 ${tab === "envio" ? "font-bold border-b-2 border-primary-700" : ""}`} onClick={() => setTab("envio")}>
            Envío
          </button>
        </div>
        {tab === "general" && (
          <div className="flex flex-col h-64 space-y-4 overflow-y-scroll">
            <DetailsRow title="Cliente" value={selected["nombre de cliente"]} />
            <DetailsRow title="Email" value={selected["email de cliente"]} />
            <DetailsRow title="Telefono" value={selected["teléfono"]} />
            <DetailsRow title="Monto" value={"$" + (selected["monto de compra"] || "").toLocaleString()} />
            <DetailsRow title="Estatus de orden" value={selected["status de orden"]} />
            <DetailsRow title="Procesador" value={selected["procesador"]} />
            <DetailsRow title="ID en procesador" value={selected["id de compra en procesador"]} />
            <DetailsRow title="Estatus en procesador" value={selected["status de orden en procesador"]} />
            <DetailsRow title="Fecha de creación de orden" value={selected["fecha de creación de orden"] ? moment(selected["fecha de creación de orden"]).format("DD MMM YYYY HH:mm") : ""} />
            <DetailsRow title="Fecha de intento de compra" value={selected["fecha de intento de compra"] ? moment(selected["fecha de intento de compra"]).format("DD MMM YYYY HH:mm") : ""} />
            <DetailsRow title="Fecha de pago" value={selected["fecha de pago"] ? moment(selected["fecha de pago"]).format("DD MMM YYYY HH:mm") : ""} />
          </div>
        )}
        {tab === "envio" && (
          <div className="flex flex-col h-64 space-y-4">
            {selected["método de entrega"]==="Envío a domicilio" && (
              <>
                <DetailsRow title="Dirección" value={selected["calle 1"] + "," + selected["calle 3"]} />
                <DetailsRow title="Codigo Postal" value={selected["código postal"]} />
                <DetailsRow title="Ciudad" value={selected["ciudad"]} />
                <DetailsRow title="Estado" value={selected["estado"]} />
                <DetailsRow title="País" value={selected["país"]} />
              </>
            )}
            <DetailsRow title="Tipo de Envío" value={selected["tipo de envío"]} />
            <DetailsRow title="Detalle de Envío" value={selected["detalle de envío"]} />
          </div>
        )}
      </div>
    </Modal>
  );
}

function DetailsRow({ title = "Título", value = "Valor" }) {
  return (
    <div className="flex space-x-8">
      <div className="text-xs text-right text-neutral-300 basis-1/3">{title}</div>
      <div className="text-xs text-neutral-500 basis-2/3">{value}</div>
    </div>
  );
}

/*
//bodega:"no disponible"
//calle 1:"Calle Francisco I. Madero 118"
//calle 2:""
//calle 3:"Agencia Municipal de Viguera"
//ciudad:"Trinidad de Viguera"
//código postal:"68276"
//detalle de envío:"3 a 5 días hábiles"
//email de cliente:"suriluis07@gmail.com"
//estado:"oax"
//fecha de creación de orden:"2022-10-02 13:20:47"
//fecha de intento de compra:"2022-10-02 13:27:06"
//fecha de pago:"2022-10-02 13:27:09"
fraude:false
id de cliente:"c_4416171fae504546af38ec32ace37961"
//id de compra en procesador:"50108926606"
//id de orden:"0a782788b56c4d0799c1c4b9b8b90cc6"
//monto de compra:4194
//nombre de cliente:"Surisaday Faran Luis"
//país:"México"
//procesador:"MERCADOPAGO"
recoger en tienda:false
riesgo de fraude:"bajo"
//status de orden:"Pagado"
//status de orden en procesador:"approved"
//teléfono:"9515101256"
//tienda:"prissa"
//tipo de envío:"Nacional Gratis (Estafeta)"
tipo de pago:"TC"
*/
export default DetailsModal;
